<template>
  <div>
    <!--begin::customer-->
    <div class="row m-0 w-100">
      <div class="col-md-8 p-5">
        <div class="form-group">
          <h5>
            <span class="pl-3"><i class="fa fa-user-circle" style="font-size: 27px;"></i></span>
            <span>{{ customer ? customer.fullname : '' }}</span>
          </h5>
        </div>

        <div class="form-group">
          <div class="row">
<!--            <div class="col-md-12 my-4">-->
<!--              <p>{{ $t('payment_method') }}<span class="text-danger">*</span></p>-->
<!--              <div class="d-flex justify-content-between">-->
<!--                <div v-for="(row , index) in payments_methods" :key="index" class="text-center col-md-3">-->
<!--                  <b-button :disabled="payment_status[row.id]"-->
<!--                            :variant="(paymentMethodSelectionList[row.id] && paymentMethodSelectionList[row.id].id == row.id)? 'primary w-100': 'outline-primary w-100'"-->
<!--                            @click="addMorePaymentMethodSelect(row , row.id)">{{ row.title }}-->
<!--                  </b-button>-->
<!--                  <div v-if="paymentMethodSelectionList[row.id] && paymentMethodSelectionList[row.id].id"-->
<!--                       class="col-md-12 p-0 mt-7">-->
<!--                    <input :value="paymentMethodSelectionList[row.id].amount" class="form-control text-center" min="0"-->
<!--                           type="number"-->
<!--                           @input="updateAmountValue($event, row.id)">-->
<!--                    <span class="text-muted">{{-->
<!--                        (paymentMethodSelectionList[row.id].method_type_data && paymentMethodSelectionList[row.id].method_type_data.name) ? paymentMethodSelectionList[row.id].method_type_data.name : ''-->
<!--                      }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-md-12 my-4">
              <p>{{ $t('payment_method') }}<span class="text-danger">*</span></p>
              <div class="d-flex justify-content-between">
                <div v-for="(row , index) in payments_methods" :key="index" class="text-center col-md-3">
                  <b-button @click="addMorePaymentMethodSelect(row , row.id)" :variant="(paymentMethodSelectionList[row.id] && paymentMethodSelectionList[row.id].id == row.id)? 'primary w-100': 'outline-primary w-100'" :disabled="payment_status[row.id]">{{ row.title }}</b-button>
                  <div v-if="paymentMethodSelectionList[row.id] && paymentMethodSelectionList[row.id].id" class="col-md-12 p-0 mt-7">
                    <input type="number" min="0" class="form-control text-center" :value="paymentMethodSelectionList[row.id].amount" @input="updateAmountValue($event, row.id)">
                    <span class="text-muted">{{ (paymentMethodSelectionList[row.id].method_type_data && paymentMethodSelectionList[row.id].method_type_data.name) ? paymentMethodSelectionList[row.id].method_type_data.name : '' }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="is_show_cheque_details" class="row p-4">
              <div class="col-md-6 my-4">
                <label>{{ $t('name_holder_of_check') }}</label>
                <input v-model="cheque_details.name_holder_of_check" class="form-control" type="text"/>
              </div>
              <div class="col-md-6 my-4">
                <label>{{ $t('check_number') }}</label>
                <input v-model="cheque_details.check_number" class="form-control" type="text"/>
              </div>
              <div class="col-md-6 my-4">
                <label>{{ $t('bank') }}</label>
                <input v-model="cheque_details.bank" class="form-control" type="text"/>
              </div>
              <div class="col-md-6 my-4">
                <label>{{ $t('amount') }}</label>
                <input v-model="cheque_details.amount" class="form-control" type="text"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div v-if="accounting_System_per_invoice" class="col-lg-6">
              <label>{{ $t('accounts') }}</label>
              <treeselect
                  :disabled="isDisabled"
                  :load-options="loadOptions"
                  :multiple="false"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :options="accounts"
                  :placeholder="$t('Select')"
                  :searchable="true"
                  :show-count="true"
                  :value="account_id"
                  @input="updateValue($event)">
              </treeselect>
            </div>
          </div>
        </div>
        <p><b>{{ $t('order_details') }}</b></p>
        <div class="border-2 mt-5 mb-10">
          <div class="p-4 d-flex text-center">
            <p class="mb-0 border-with-space col" style="padding-left: 22px;"><b
                class="d-block">{{ $t('point_of_sales.subTotal') }}</b><span class="d-block">{{ total_items }}
              {{ currencyName }}</span></p>
            <p class="mb-0 border-with-space col" style="padding-left: 22px;"><b
                class="d-block">{{ $t('point_of_sales.discount') }}</b><span class="d-block">{{ total_dis }}
              {{ currencyName }}</span></p>
            <p class="mb-0 border-with-space col" style="padding-left: 22px;"><b
                class="d-block">{{ $t('point_of_sales.shipping') }}</b><span class="d-block">{{ shipping_val }}
              {{ currencyName }}</span></p>
            <p v-if="isApplyTaxesInvoice" class="mb-0 border-with-space col" style="padding-left: 22px;"><b
                class="d-block">{{ $t('point_of_sales.tax') }}</b><span class="d-block">{{ totalTax }} {{
                currencyName
              }}</span></p>
            <p class="mb-0 col" style="padding-left: 10px;padding-right: 10px;"><b
                class="d-block">{{ $t('point_of_sales.total') }}</b><span class="d-block">{{ total_final }}
              {{ currencyName }}</span></p>
          </div>
          <hr style="height: 2px; border-top: 2px dashed rgb(189, 195, 199); color: rgb(255, 255, 255); background-color: rgb(255, 255, 255);">
          <div class="p-4 d-flex text-center">
            <p class="mb-0 border-with-space"><b class="d-block">{{ $t('point_of_sales.zero') }}</b><span>0.00
              {{ currencyName }}</span></p>
            <p class="mb-0" style="padding-left: 10px;padding-right: 10px;"><b
                class="d-block">{{ $t('point_of_sales.recovery') }}</b><span>0.00 {{ currencyName }}</span></p>
          </div>
          <hr style="height: 2px; border-top: 2px dashed rgb(189, 195, 199); color: rgb(255, 255, 255); background-color: rgb(255, 255, 255);">
          <div class="p-4 d-flex text-center">
            <p class="mb-0 border-with-space"><b class="d-block">{{
                $t('point_of_sales.net_payable')
              }}</b><span>{{ total_final }} {{ currencyName }}</span></p>
            <p class="mb-0 border-with-space"><b class="d-block">{{
                $t('point_of_sales.paid')
              }}</b><span>{{ amount_form_customer }} {{ currencyName }}</span></p>
            <!--            <p class="mb-0 border-with-space" v-if="method2_name"><b class="d-block">{{ method2_name }}</b><span>{{ method2_amount }} {{ currencyName }}</span></p>-->
            <p class="mb-0" style="padding-left: 10px;padding-right: 10px;"><b
                class="d-block">{{ $t('point_of_sales.change') }} </b><span>{{ amount_to_customer }} {{
                currencyName
              }}</span></p>
          </div>
        </div>
        <div class="mt-5">
          <div class="d-flex w-100">
            <button class="btn btn-secondary w-100  ml-10 mr-10" @click="cancel">{{ $t('cancel') }}</button>
            <button :disabled="send_request_disabled" class="btn btn-primary w-100  ml-10 mr-10" @click="save">
              {{ $t('confirm') }}
            </button>
          </div>
        </div>

        <!--        <div class="form-group">-->
        <!--          <label>{{ $t('payment_amount') }}</label>-->
        <!--          <input type="number" min="0" class="form-control" v-model="payment_amount"/>-->
        <!--        </div>-->
        <!--        <div class="buttons-2">-->
        <!--          <div class="button button-st-2" @click="selectNumber('1')">1</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('2')">2</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('3')">3</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('4')">4</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('5')">5</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('6')">6</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('7')">7</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('8')">8</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('9')">9</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('0')">0</div>-->
        <!--          <div class="button button-st-2" @click="selectNumber('.')">.</div>-->
        <!--          <div class="button button-st-2" @click="restCalc">←</div>-->
        <!--        </div>-->
      </div>
      <div class="col-md-4 p-5 d-flex align-items-center justify-content-center" style="background: #efefef;">
        <div style="padding: 15px;background: #ffff;border: 1px solid #e3e3e3;border-radius: 7px;">
          <div class="form-group text-center">
            <b>{{ $t('payment_amount') }}</b>
            <input v-model="payment_amount" class="form-control calc-input" min="0" type="number"
                   @input="enabledIsCalcUsed"/>
          </div>
          <div class="buttons-2">
            <div class="button button-st-2" @click="selectNumber('9')">9</div>
            <div class="button button-st-2" @click="selectNumber('8')">8</div>
            <div class="button button-st-2" @click="selectNumber('7')">7</div>
            <div class="button button-st-2" @click="selectNumber('6')">6</div>
            <div class="button button-st-2" @click="selectNumber('5')">5</div>
            <div class="button button-st-2" @click="selectNumber('4')">4</div>
            <div class="button button-st-2" @click="selectNumber('3')">3</div>
            <div class="button button-st-2" @click="selectNumber('2')">2</div>
            <div class="button button-st-2" @click="selectNumber('1')">1</div>
            <div class="button button-st-2" @click="selectNumber('0')">0</div>
            <div class="button button-st-2" @click="selectNumber('.')">.</div>
            <div class="button button-st-2 btn-primary" @click="restCalc">←</div>
          </div>
        </div>


        <!--        <div class="border-2 bg-f9fbfc">-->
        <!--          <div class="d-flex justify-content-between p-4 bg-ebf1f3">-->
        <!--            <div>-->
        <!--              <h5>{{ $t('client') }}</h5>-->
        <!--              <p class="mb-0">{{ customer ? customer.fullname : '' }}</p>-->
        <!--              <p>{{ customer ? customer.email : '' }}</p>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <b-icon icon="person-plus-fill" font-scale="3"></b-icon>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="p-4">-->
        <!--            <div class="form-group mb-0">-->
        <!--              <label>{{ $t('payment_list') }}</label>-->
        <!--              <div v-for="(item, index) in paymentMethodSelectionList" :key="`item_${index}`" class="input-group mb-2">-->
        <!--                <input type="text" class="form-control" :value="getPaymentsMethodTitle(item.id)" readonly>-->
        <!--                <input type="number" min="0" class="form-control" :value="item.amount" @input="updateAmountValue($event, index)">-->
        <!--                <div class="input-group-prepend">-->
        <!--                  <span class="input-group-text">{{ currencyName }}</span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="p-4">-->
        <!--            <div class="form-group">-->
        <!--              <label>{{ $t('discount') }}</label>-->
        <!--              <div class="input-group">-->
        <!--                <input type="number" min="0" class="form-control" disabled v-model="total_dis"/>-->
        <!--                <select v-model="discount_type" disabled class="custom-select">-->
        <!--                  <option value="percentage">%</option>-->
        <!--                  <option value="value">{{ currencyName }}</option>-->
        <!--                </select>-->
        <!--              </div>-->

        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
      <!--      <div class="col-md-4 pt-3">-->
      <!--        <div class="border-2 bg-f9fbfc">-->
      <!--          <div class="d-flex justify-content-between p-4 bg-ebf1f3">-->
      <!--            <div>-->
      <!--              <h5>{{ $t('client') }}</h5>-->
      <!--              <p class="mb-0">{{ customer ? customer.fullname : '' }}</p>-->
      <!--              <p>{{ customer ? customer.email : '' }}</p>-->
      <!--            </div>-->
      <!--            <div>-->
      <!--              <b-icon icon="person-plus-fill" font-scale="3"></b-icon>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="p-4">-->
      <!--            &lt;!&ndash;                        <div class="form-group mb-0">&ndash;&gt;-->
      <!--            &lt;!&ndash;                            <label>Payment method</label>&ndash;&gt;-->
      <!--            &lt;!&ndash;                            <div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                                <p class="badge badge-dark">{{payment_method}}</p>&ndash;&gt;-->
      <!--            &lt;!&ndash;                            </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
      <!--            <div class="form-group mb-0">-->
      <!--              <label>{{ $t('payment_list') }}</label>-->
      <!--              <div class="input-group">-->
      <!--                &lt;!&ndash;                                <input type="text" class="form-control" :value="payment_method" readonly>&ndash;&gt;-->
      <!--                <input type="text" class="form-control" :value="method1_name" readonly>-->
      <!--                <input type="number" min="0" class="form-control" v-model="payment_amount">-->
      <!--                <div class="input-group-prepend">-->
      <!--                  <span class="input-group-text">{{ currencyName }}</span>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="p-4">-->
      <!--            <div class="form-group">-->
      <!--              <label>{{ $t('point_of_sales.refund_discount') }}</label>-->
      <!--              <div class="input-group">-->
      <!--                <input type="number" min="0" class="form-control" disabled v-model="total_dis"/>-->
      <!--                <select v-model="discount_type" disabled class="custom-select">-->
      <!--                  <option value="percentage">%</option>-->
      <!--                  <option value="value">{{ currencyName }}</option>-->
      <!--                </select>-->
      <!--              </div>-->

      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="border-2 bg-f9fbfc mt-5">-->
      <!--          <div class="p-3">-->
      <!--            <p><b>{{ $t('point_of_sales.previous_invoice_total') }}</b></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.subTotal') }}: </span><span>{{ previousInvoiceTotal }} {{ currencyName }}</span></p>-->
      <!--          </div>-->
      <!--          <hr style="height:2px;border-top:2px dashed #bdc3c7; color:#fff; background-color:#fff">-->
      <!--          <div class="p-3">-->
      <!--            <p><b>{{ $t('order_details') }}</b></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.subTotal') }}: </span><span>{{ total_items }} {{ currencyName }}</span></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.refund_discount') }}: </span><span>{{ total_dis }} {{ currencyName }}</span></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.total') }}: </span><span>{{ total_final }} {{ currencyName }}</span></p>-->
      <!--          </div>-->
      <!--          <hr style="height:2px;border-top:2px dashed #bdc3c7; color:#fff; background-color:#fff">-->
      <!--          <div class="p-3">-->
      <!--            <p><b>{{ $t('point_of_sales.tax') }}</b></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.zero') }}: </span><span>0.00 {{ currencyName }}</span></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.recovery') }}: </span><span>0.00 {{ currencyName }}</span></p>-->
      <!--          </div>-->
      <!--          <hr style="height:2px;border-top:2px dashed #bdc3c7; color:#fff; background-color:#fff">-->
      <!--          <div class="p-3">-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.net_payable') }}: </span><span>{{ total_final }} {{ currencyName }}</span></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.paid') }}: </span><span>{{ payment_amount }} {{ currencyName }}</span></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between" v-if="method2_name"><span>{{ method2_name }}: </span><span>{{ method2_amount }} {{ currencyName }}</span></p>-->
      <!--            <p class="mb-0 d-flex justify-content-between"><span>{{ $t('point_of_sales.change') }}: </span><span>0.00 {{ currencyName }}</span></p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="mt-5">-->
      <!--          <div class="btn-group w-100" role="group">-->
      <!--            <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>-->
      <!--            <button type="reset" class="btn btn-success" :disabled="send_request_disabled" @click="save">{{ $t('confirm') }}</button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-pos-payment",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    hideSaveModal: {type: Function},
    resetList: {type: Function},
    items: null,
    totalItems: null,
    totalDis: null,
    shippingVal: null,
    customer: null,
    currencyId: null,
    currencyName: null,
    totalTax: null,
    previousInvoiceTotal: null,
    invoiceId: null,
    totalFinal: 0,
    isApplyTaxesInvoice: false,
    totalOutTax: 0,
    totalTaxTypeVal: [],
    discountTotals: {},
    paymentMethodsList: [],
    sessionId: null,
  },
  data() {
    return {
      mainRoute: '/pos/point-sale/refund/items',
      mainRouteDependency: 'base/dependency',

      validation: null,
      customer_id: null,
      num_calc: '',
      total_items: '',
      total_dis: '',
      total_final: '',
      shipping_val: '',
      payment_amount: '',
      discount_type: 'value',
      is_cash: '',
      method1_name: '',
      method2: '',
      method2_name: '',
      method2_amount: '',
      payment_method_id1: '',
      payment_method_id2: '',
      total_payment_amount: '',
      payment_method: 'Cash Refund',
      payments_methods: [],
      items_list: [],
      accounting_System_per_invoice: '',
      isDisabled: false,
      account_id: '',
      accounts: [],
      treasury_cash: null,
      treasury_card: null,
      treasury_cash_2: null,
      treasury_card_2: null,
      treasury_id: null,
      send_request_disabled: true,

      paymentMethodSelectionList: {},
      is_calc_used: false,
      payment_status: {},
      inner_payment_amount: 0,
      amount_form_customer: 0,
      amount_to_customer: 0,
      cheque_details: {
        name_holder_of_check: null,
        check_number: null,
        amount: null,
        bank: null,
      },
      is_show_cheque_details: false,


    };
  },
  watch: {
    items: function (val) {
      this.items_list = val;
    },
    totalItems: function (val) {
      this.total_items = val;
    },
    totalDis: function (val) {
      this.total_dis = val;
    },
    is_cash: function (val) {
      if (val) {
        let _method1_name = this.payments_methods.find(x => x.id == val);
        if (_method1_name) {
          this.method1_name = _method1_name.title
          this.payment_method_id1 = val
        }
        // this.getDefaultTreasury(val, 1);
      } else {
        this.method1_name = '';
      }
    },
    method2: function (val) {
      if (val) {
        this.method2_name = this.payments_methods.filter(x => x.id == val);
        if (this.method2_name[0]) {
          this.method2_name = this.method2_name[0].name
          this.payment_method_id2 = val
        }
      } else {
        this.method2_name = '';
      }
    },
    payment_amount: function (val) {
      let _amount = parseFloat(val);
      _amount = Math.abs(_amount)
      this.payment_amount = _amount;

    },
  },
  methods: {
    enabledIsCalcUsed() {
      this.is_calc_used = true;
    },
    disabledIsCalcUsed() {
      this.is_calc_used = false;
    },
    save() {
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
      this.finishedSave();
      this.getPosSetting();
      // this.getDefaultAccount();
    },
    closeAndSave() {
      this.$emit('hide-save-modal');
      this.resetAfterSave();
      this.finishedSave();
      this.getPosSetting();
      // this.getDefaultAccount();
    },
    callPrintInvoice() {
      // setTimeout(() => {
      //   document.getElementById("printInvoice").click();
      // }, 5000);
      window.open('/print/point-sale-invoice-refund', "_blank");

    },
    callPrintCreditInvoice(id) {
      if (id)
      window.open(`/print/point-sale-credit/${id}`, "_blank");

    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      let payment_amount_details = [
        {
          payment_method_id: this.payment_method_id1,
          type: this.method1_name,
          amount: this.payment_amount,
        }
      ];
      if (this.method2_amount) {
        payment_amount_details.push({
          payment_method_id: this.payment_method_id2,
          type: this.method2_name,
          amount: this.method2_amount,
        });
        if (this.method2_name) {
          this.total_payment_amount = (parseFloat(this.payment_amount) + parseFloat(this.method2_amount));
        }
      }

      const paymentOptionDetails = [];
      Object.keys(this.paymentMethodSelectionList).forEach((index) => {
        paymentOptionDetails.push({
          payment_method_id: this.paymentMethodSelectionList[index].id,
          amount: this.paymentMethodSelectionList[index].amount,
          type: () => {
            let obj = this.payments_methods.find(paymentMethod => {
              return paymentMethod.id == this.paymentMethodSelectionList[index].id;
            })
            if (obj)
              return obj.title
            else
              return '';
          },
          method_type_id: this.paymentMethodSelectionList[index].method_type_id,
          method_type_data: this.paymentMethodSelectionList[index].method_type_data,
        });
      })

      let _items_list = this.items_list.filter(row => row.is_return);
      _items_list = _items_list.map((row)=>{
        row.unit_price = row.sale_price;
        return row;
      });

      ApiService.post(this.mainRoute, {
        items_list: _items_list,
        invoice_subtotal: this.total_items,
        invoice_total_shipping: this.shipping_val,
        total_dis: this.total_dis,
        total_tax: this.totalTax,
        total_final: this.total_final,
        customer_id: this.customer_id,
        currency_id: this.currencyId,
        invoice_id: this.invoiceId,
        payment_amount: this.payment_amount,
        payment_amount_details: paymentOptionDetails,
        payment_method: this.payment_method_id1,
        total_payment_amount: this.total_payment_amount,
        account_id: this.account_id,
        treasury_id: this.treasury_id,
        is_apply_taxes_invoice: this.isApplyTaxesInvoice,
        total_out_tax: this.totalOutTax,
        total_tax_type_val: this.totalTaxTypeVal,
        session_id: this.sessionId,
        ...this.discountTotals,

      }).then((response) => {
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
        this.closeAndSave();
        this.$emit('reset-list');
        if (response.data.data.is_apply_taxes_invoice){
          this.callPrintCreditInvoice(response.data.data.id);
        }else{
          this.callPrintInvoice();
        }

        // document.getElementById('invoice').contentWindow.location.reload()
      }).catch((error) => {
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
      });
    },
    resetAfterSave() {
    },

    async getPosSetting() {
      await ApiService.get(`${this.mainRouteDependency}/pos_setting`).then((response) => {
        this.accounting_System_per_invoice = response.data.data.accounting_System_per_invoice;
      });
    },

    loadOptions() {
    },

    getAccounts() {
      ApiService.get(this.mainRouteDependency + "/account_parents").then((response) => {
        this.accounts = response.data.data;
      });
    },

    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/returns`).then((response) => {
        if (this.accounting_System_per_invoice === true) {
          this.account_id = null;
          this.isDisabled = false;
        } else if (this.accounting_System_per_invoice === false) {
          this.account_id = response.data.data.id;
          this.isDisabled = true;
        }
      });
    },
    updateValue(value) {
      this.account_id = value;
    },


    addMorePaymentMethodSelect(row, index) {
      let _calc = 0;
      if (this.paymentMethodSelectionList[index]) {
        let _keys = Object.keys(this.paymentMethodSelectionList);
        if (_keys.length <= 1) {
          return false;
        }
        delete this.paymentMethodSelectionList[index];

        _keys = Object.keys(this.paymentMethodSelectionList);
        if (this.paymentMethodSelectionList[1] && _keys.length == 1){
          // reset all actions for payment enable
          let _keys_status = Object.keys(this.payment_status);
          _keys_status.forEach((key) => {
            this.payment_status[key] = false;
          })
        }else {
          this.validAndInvalidPayment(_calc, index);
        }
      } else {
        let _calc = this.total_final - this.inner_payment_amount;
        if (_calc < 0) {
          _calc = 0;
        }
        this.paymentMethodSelectionList[index] = {
          id: row.id,
          amount: _calc,
          currency: this.currencyName,
          method_type_data: {},
          method_type_id: null,
        };
        if (this.paymentMethodSelectionList[index])
          this.getDefaultTreasuryForMethodSelected(row.id, row.id)

        this.updatePaymentMethod(row.id, row.id);
        this.validAndInvalidPayment(_calc, index);
      }




      this.refreshPaymentList();
      this.updatePaymentAmountValue();
    },
    refreshPaymentList() {
      let _data = this.paymentMethodSelectionList
      this.paymentMethodSelectionList = {};
      this.paymentMethodSelectionList = _data;

    },
    updatePaymentMethod(event, index) {
      let _id = (event.target && event.target.value) ? event.target.value : event;
      this.paymentMethodSelectionList[index].id = _id;

    },
    getDefaultTreasuryForMethodSelected(id, index = 1) {
      if (id) {
        let _payments_method = this.payments_methods.find((row) => row.id == id);

        if (_payments_method) {
          this.paymentMethodSelectionList[index].method_type_id = _payments_method.payment_method_model ? _payments_method.payment_method_model.id : null;
          this.paymentMethodSelectionList[index].method_type_data = _payments_method.payment_method_model ? _payments_method.payment_method_model : {};
        }
      }
    },
    updateAmountValue(event, index) {
      this.disabledIsCalcUsed();


      /***************** Used for current input changed ********************/
      let _val_input = event.target.value ? parseFloat(event.target.value) : 0;
      let _total_final = this.total_final ? parseFloat(this.total_final) : 0;
      let _inner_amount_without_cash = 0;
      Object.keys(this.paymentMethodSelectionList).forEach((_index) => {
        if (_index != 1 && _index != index)
          _inner_amount_without_cash = _inner_amount_without_cash + ((this.paymentMethodSelectionList[_index] && this.paymentMethodSelectionList[_index].amount) ? parseFloat(this.paymentMethodSelectionList[_index].amount) : 0)
      });
      // if the amount less than 0 ex: -10
      if (_val_input < 0) {
        event.preventDefault();
        this.paymentMethodSelectionList[index].amount = 0;
        this.refreshPaymentList();
        this.updatePaymentAmountValue();
        this.updateRefundAmount();
        return false;
      } else if (index != 1 && (_val_input >= _total_final || _val_input > (_total_final - _inner_amount_without_cash)) && _inner_amount_without_cash != 0) {
        event.preventDefault();
        this.paymentMethodSelectionList[index].amount = _total_final - _inner_amount_without_cash;
        this.refreshPaymentList();
        this.updatePaymentAmountValue();
        this.updateRefundAmount();
        return false;
      } else if (index != 1 && _val_input >= (_total_final - _inner_amount_without_cash)) {
        event.preventDefault();
        this.paymentMethodSelectionList[index].amount = _total_final - _inner_amount_without_cash;
        this.refreshPaymentList();
        this.updatePaymentAmountValue();
        this.updateRefundAmount();
        return false;
      }
      /******************input*******************/


      this.validAndInvalidPayment(event.target.value, index);

      this.paymentMethodSelectionList[index].amount = event.target.value;
      this.updatePaymentAmountValue();
      this.updateRefundAmount();
    },

    // validAndInvalidPayment: _.debounce(function (amount, index) {
    //
    //   let _val_input = amount ? parseFloat(amount) : 0;
    //   let _total_final = this.total_final ? parseFloat(this.total_final) : 0;
    //   let _inner_amount = 0;
    //   Object.keys(this.paymentMethodSelectionList).forEach((index) => {
    //     _inner_amount = _inner_amount + ((this.paymentMethodSelectionList[index] && this.paymentMethodSelectionList[index].amount) ? parseFloat(this.paymentMethodSelectionList[index].amount) : 0)
    //   });
    //   let _inner_amount_without_cash = 0;
    //   Object.keys(this.paymentMethodSelectionList).forEach((index) => {
    //     if (index != 1)
    //       _inner_amount_without_cash = _inner_amount_without_cash + ((this.paymentMethodSelectionList[index] && this.paymentMethodSelectionList[index].amount) ? parseFloat(this.paymentMethodSelectionList[index].amount) : 0)
    //   });
    //   if (_val_input > _total_final) {
    //     this.paymentMethodSelectionList[index].amount = _total_final;
    //   }
    //   // delete cash payment if the sum all payment without it equal total invoice
    //   // and disabled another payment not used
    //   // if (_inner_amount_without_cash == _total_final){
    //   //   delete this.paymentMethodSelectionList[1];
    //   //   let _keys = Object.keys(this.paymentMethodSelectionList);
    //   //   let _keys_status = Object.keys(this.payment_status);
    //   //   _keys_status.forEach((key) => {
    //   //     if (!_keys.includes(key))
    //   //       this.payment_status[key] = true;
    //   //   })
    //   // }else
    //   //     // if all summation arrived to total invoice and disabled another payment not used
    //   // if (_inner_amount >= _total_final){
    //   //   let _keys = Object.keys(this.paymentMethodSelectionList);
    //   //   let _keys_status = Object.keys(this.payment_status);
    //   //   _keys_status.forEach((key) => {
    //   //     if (!_keys.includes(key))
    //   //       this.payment_status[key] = true;
    //   //   })
    //   // } else {
    //   //   // reset all actions for payment enable
    //   //   let _keys_status = Object.keys(this.payment_status);
    //   //   _keys_status.forEach((key) => {
    //   //     this.payment_status[key] = false;
    //   //   })
    //   // }
    //   this.updatePaymentAmountValue();
    //   this.updateRefundAmount();
    //   this.refreshPaymentList();
    //
    // }, 500),
    validAndInvalidPayment: _.debounce(function (amount, index){

      let _val_input = amount ? parseFloat(amount) : 0;
      let _total_final = this.total_final ? parseFloat(this.total_final) : 0;
      let _inner_amount = 0;
      Object.keys(this.paymentMethodSelectionList).forEach((index) => {
        _inner_amount = _inner_amount + ((this.paymentMethodSelectionList[index] && this.paymentMethodSelectionList[index].amount) ? parseFloat(this.paymentMethodSelectionList[index].amount) : 0)
      });
      let _inner_amount_without_cash = 0;
      Object.keys(this.paymentMethodSelectionList).forEach((index) => {
        if (index != 1)
          _inner_amount_without_cash = _inner_amount_without_cash + ((this.paymentMethodSelectionList[index] && this.paymentMethodSelectionList[index].amount) ? parseFloat(this.paymentMethodSelectionList[index].amount) : 0)
      });

      // delete cash payment if the sum all payment without it equal total invoice
      // and disabled another payment not used
      if (_inner_amount_without_cash == _total_final){
        delete this.paymentMethodSelectionList[1];
        let _keys = Object.keys(this.paymentMethodSelectionList);
        let _keys_status = Object.keys(this.payment_status);
        _keys_status.forEach((key) => {
          if (!_keys.includes(key))
            this.payment_status[key] = true;
        })
      }else
          // if all summation arrived to total invoice and disabled another payment not used
      if (_inner_amount >= _total_final){
        let _keys = Object.keys(this.paymentMethodSelectionList);
        let _keys_status = Object.keys(this.payment_status);
        _keys_status.forEach((key) => {
          if (!_keys.includes(key))
            this.payment_status[key] = true;
        })
      } else {
        // reset all actions for payment enable
        let _keys_status = Object.keys(this.payment_status);
        _keys_status.forEach((key) => {
          this.payment_status[key] = false;
        })
      }
      this.updatePaymentAmountValue();
      this.updateRefundAmount();
      this.refreshPaymentList();

    }, 500),
    updatePaymentAmountValue() {
      this.payment_amount = 0;
      Object.keys(this.paymentMethodSelectionList).forEach((index) => {
        this.payment_amount = this.payment_amount + ((this.paymentMethodSelectionList[index] && this.paymentMethodSelectionList[index].amount) ? parseFloat(this.paymentMethodSelectionList[index].amount) : 0)
      });
      this.inner_payment_amount = 0;
      Object.keys(this.paymentMethodSelectionList).forEach((index) => {
        this.inner_payment_amount = this.inner_payment_amount + ((this.paymentMethodSelectionList[index] && this.paymentMethodSelectionList[index].amount) ? parseFloat(this.paymentMethodSelectionList[index].amount) : 0)
      });
      // this.inner_payment_amount = this.$_.sumBy(this.paymentMethodSelectionList, function (row) {
      //   console.log(row);
      //   return ((row && row.amount) ? parseFloat(row.amount) : 0);
      // });
    },

    //
    // selectNumber(num) {
    //   this.num_calc += num;
    //   this.payment_amount = this.num_calc;
    //   this.method2_amount = (this.total_final - this.payment_amount).toFixed(2);
    //   // this.total_items = this.num_calc;
    //   // this.total_final = ((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis? parseFloat(this.total_dis) : 0) + (this.shipping_val? parseFloat(this.shipping_val) : 0) + (this.totalTax? parseFloat(this.totalTax) : 0)).toFixed(2);
    // },
    selectNumber(num) {
      this.enabledIsCalcUsed();
      this.num_calc += num;
      this.payment_amount = this.num_calc;
      let _calc = this.total_final - this.inner_payment_amount;
      this.method2_amount = (_calc).toFixed(2);
      if (this.paymentMethodSelectionList[1]) {
        this.paymentMethodSelectionList[1].amount = this.payment_amount;
        this.refreshPaymentList();
      }
      // this.total_items = this.num_calc;
      // this.total_final = ((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis? parseFloat(this.total_dis) : 0) + (this.shipping_val? parseFloat(this.shipping_val) : 0) + (this.totalTax? parseFloat(this.totalTax) : 0)).toFixed(2);
    },
    updateRefundAmount() {
      // this.amount_form_customer = (this.paymentMethodSelectionList[1] && this.paymentMethodSelectionList[1].amount) ? this.paymentMethodSelectionList[1].amount : 0;
      this.amount_form_customer = this.payment_amount;
      // let _to_customer = (this.payment_amount? parseFloat(this.payment_amount) : 0) - (this.amount_form_customer? parseFloat(this.amount_form_customer) : 0) + ((this.amount_form_customer? parseFloat(this.amount_form_customer) : 0) - (this.total_final? parseFloat(this.total_final) : 0));
      let _to_customer = (this.payment_amount ? parseFloat(this.payment_amount) : 0) - (this.total_final ? parseFloat(this.total_final) : 0);
      this.amount_to_customer = (_to_customer > 0) ? _to_customer : 0;
    },
    restCalc() {
      this.num_calc = '';
    },
    getPaymentsMethods(paymentMethodsList) {
      // await ApiService.get(`${this.mainRouteDependency}/pos-payment-methods`).then((response) => {


        this.payments_methods = paymentMethodsList.map((_row) => {
          return {
            cash: _row.cash,
            id: _row.id,
            is_active: _row.is_active,
            title: _row.payment_method_name,
            payment_method_model: _row.payment_method_model,
          }
        });
      paymentMethodsList.forEach((_row) => {
          this.payment_status[_row.id] = false;
          if (_row.is_default) {
            this.payment_method_id1 = _row.id;
            this.paymentMethodSelectionList[_row.id] = {
              id: _row.id,
              amount: _row.is_default ? parseFloat(this.payment_amount) : 0,
              currency: this.currencyName,
              method_type_id: _row.payment_method_model ? _row.payment_method_model.id : null,
              method_type_data: _row.payment_method_model ? _row.payment_method_model : {},
            };
          }
        });

        this.send_request_disabled = false;
      // });
    },
    // getDefaultTreasury(id, _for) {
    //   if (id)
    //     ApiService.get(`${this.mainRouteDependency}/pos_default_treasury/${id}`).then((response) => {
    //
    //       if (_for == 1) {
    //         switch (id) {
    //           case 1:
    //             this.treasury_cash = response.data.data;
    //             this.treasury_id = response.data.data.id;
    //             break;
    //
    //           case 3:
    //             this.treasury_card = response.data.data;
    //             this.treasury_id = response.data.data.id;
    //             break;
    //
    //         }
    //       }
    //       if (_for == 2) {
    //         switch (id) {
    //           case 1:
    //             this.treasury_cash_2 = response.data.data;
    //             break;
    //
    //           case 3:
    //             this.treasury_card_2 = response.data.data;
    //             break;
    //
    //         }
    //       }
    //       this.send_request_disabled = false;
    //     });
    // },
    // async getDefaultPaymentMethod() {
    //   await ApiService.get(`${this.mainRouteDependency}/pos_default_payment_method`).then((response) => {
    //     this.is_cash = response.data.data.id;
    //     this.payment_method_id1 = response.data.data.id;
    //     this.method1_name = response.data.data.title;
    //     this.method2 = '';
    //   });
    // },
  },

  mounted() {

    // let promise = this.getPosSetting();
    // let promise2 = this.getDefaultPaymentMethod();
    // Promise.all([promise]).then(() => {
      // this.getAccounts();
      // this.getDefaultAccount();


      this.total_items = this.totalItems ? this.totalItems : 0;
      this.total_dis = this.totalDis ? this.totalDis : 0;
      this.shipping_val = this.shippingVal ? this.shippingVal : 0;
      this.total_final = this.totalFinal ? this.totalFinal : 0;//((this.totalItems ? parseFloat(this.totalItems) : 0) - (this.totalDis ? parseFloat(this.totalDis) : 0) + (this.shippingVal ? parseFloat(this.shippingVal) : 0) + (this.totalTax ? parseFloat(this.totalTax) : 0)).toFixed(2);
      this.items_list = this.items;
      this.customer_id = this.customer.id;
      this.payment_amount = this.total_final;
      this.inner_payment_amount = this.total_final;

    this.getPaymentsMethods(this.paymentMethodsList);
      // let _payment_method_id = this.payment_method_id1 ? this.payment_method_id1 : 1;
      // this.paymentMethodSelectionList[_payment_method_id] =
      //     {
      //       id: _payment_method_id,
      //       amount: parseFloat(this.payment_amount),
      //       currency: this.currencyName,
      //       method_type_id: null,
      //       method_type_data: {},
      //     };
      // this.updatePaymentMethod(_payment_method_id, _payment_method_id);
      // this.getDefaultTreasuryForMethodSelected(_payment_method_id, _payment_method_id);

    // });
  },
};
</script>

<style>
.bg-ebf1f3 {
  background-color: #ebf1f3;
}

.bg-f9fbfc {
  background-color: #f9fbfc;
}

.border-2 {
  border-radius: 5px;
  border: 2px solid #babde2;
}

.row-action-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
</style>
